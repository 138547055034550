import Image from "next/image"
import Link from "next/link"
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import React, { useState, useEffect } from 'react'

function CmsCardGroup({ component }) {

    return (
        <div className={`grid grid-cols-${component.options.itemsPerRow}`} id={`${component.id ?? ''}`}>
        {component.options.cards.map(function (card,i) {
            return (
                <div key={`card_${i}`} data-aos="fade-up">
                    <div className="text-center card">
                        <div className="image">
                            {card.image.url != '' &&
                            <Image
                                alt=""
                                src={card.image.url}
                                layout='responsive'
                                width={card.image.width}
                                height={card.image.height}
                                priority={1}
                            />
                            }
                        </div>
                        <div className="caption"
                            dangerouslySetInnerHTML={{ __html: card.caption }} 
                        />
                        <div className="p-4">
                        {typeof card.url != 'undefined' && card.url != '' && 
                                <Link href={ card.url }>
                                    <a className='card-btn' target={card.url.startsWith('http') ? '_blank' : '_self' }><span>+</span> INFO</a>
                                </Link>
                        }
                    </  div>
                    </div>
                </div>
            )
        })}
        </div>
    )
}

export default CmsCardGroup